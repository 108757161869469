<template>
  <div id="message-view">
    {{ message }}
  </div>
</template>

<script>
export default {
  name: 'TwitterRedirect',
  data() {
    return {
      message: '',
    }
  },
  async mounted() {
    const code = this.$route.query.c // query c === code
    // switch
    await this.getMessage(code)
  },
  methods: {
    async getMessage(code) {
      //console.log('in func :', code)
      switch (code) {
        case '1': // code 1 = already subscribed is defined on .../superwa/couchdb/twitter/get-token
          this.message = 'Twitter account was already subscribed'
          break
        case '2':
          this.message = 'Twitter account was successfully subscribed'
          break
        default:
          this.message = 'Error while processing the request'
      }
    },
  },
}
</script>

<style>
#message-view {
  display: flex;
  align-items: flex-start;
}
</style>